/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'

window['moinsen_messurementId'] = 'G-8WFTP3KZKG'
const firebaseConfig = {
  apiKey: 'AIzaSyDKc-WYu99pNU9lHrNpsJyPhmMPvCJixJQ',
  authDomain: 'moinsen-website-2022.firebaseapp.com',
  projectId: 'moinsen-website-2022',
  storageBucket: 'moinsen-website-2022.appspot.com',
  messagingSenderId: '661963249395',
  appId: '1:661963249395:web:fb14be21eb66f383b6c986',
  measurementId: window['moinsen_messurementId'],
}

// disable tracking from the beginning, activate it by opt-in
window['ga-disable-' + window['moinsen_messurementId']] = true
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

function applyCookieConsent() {
  var consentCookie = document.cookie
    .split(';')
    .find(item => item.indexOf('moinc') > -1)
  var _consent = consentCookie
    ? JSON.parse(consentCookie.split('=')[1])
    : new Array(3).fill(null)

  // functional
  if (_consent[0]) {
  }
  // tracking
  if (_consent[1]) {
    window['ga-disable-' + window['moinsen_messurementId']] = false
  } else if (!_consent[1]) {
    // disable tracking, when the user opt-out by consent box
  }
  // marketing
  if (_consent[2]) {
  }
}
window['applyCookieConsent'] = applyCookieConsent
// required for privacy page, to opt-out later
window['optOutGa'] = function () {
  window['ga-disable-' + window['moinsen_messurementId']] = true
}
applyCookieConsent()

// Initialize Firebase
